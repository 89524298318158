import {WebSocketPrinter} from "./core/printer";
import {PrinterTray} from "./ui/tray/tray";
import {StationManager} from "./core/station_manager";
import {PrintingBridge} from "./core/bridge";

declare var $;
const frappe = window['frappe'];
frappe.provide("frappe.silent_print");

$(document).on('app_ready', function () {

    frappe.silent_print.printService = new WebSocketPrinter({
      autoReconnect: true
    });

    frappe.silent_print.tray = new PrinterTray(frappe.silent_print.printService);
    frappe.silent_print.stationManager = new StationManager(frappe.silent_print.printService);
    frappe.silent_print.bridge = new PrintingBridge(frappe.silent_print.printService);
    frappe.silent_print.printService.connect();
})
