import {TrayPage} from "./page";
import {PrintStation} from "../../core/station_manager";

declare var __;
declare var $;
const frappe = window['frappe'];

export class PrinterPage extends TrayPage {

  setup() {
    this.$container.empty();
    this.$container.html(`
				<div class="printer-null-state">
					<div class="text-center">
                        <img src="/assets/frappe/images/ui-states/empty-app-state.svg" alt="Generic Empty State" class="null-state">
                        <div class="title">${__('No printers available')}</div>
                        <div class="subtitle">
                            ${__('Please open any WMS system connected to a printer.')}
				        </div>
				    </div>
				</div>
			`);
  }

  render(stations: PrintStation[]) {
    stations = stations.filter(s => s.broadcasters.length || s.isActive());
    if (stations.length) {
      this.$container.empty();
      stations.forEach(s => {
        this.$container.append(this.getStationHTML(s));
      });
    } else {
      this.setup();
    }
  }

  private getStationHTML(station: PrintStation) {
    if (station.broadcasters.length) {
      const st = $(`<a class="recent-item notification-item station-item ${station.isActive() ? 'station-active' : 'station-inactive'}" onclick="return false;" data-name="${station.stationName}">
                    <div class="notification-body">
                        <div class="message">
                            <div class="station-title">${station.stationName}</div>
                            <div class="notification-timestamp text-muted">
                                ${__('{0} available printer{1}', [station.printers.length, station.printers.length === 1 ? '' : 's'])}
                            </div>
                        </div>
                    </div>
                    <div class="station-status">
                        <span class="indicator-pill whitespace-nowrap ${station.isActive() ? 'blue' : 'gray'}">
                            ${station.isActive() ? __('Active') : __('Online')}
                        </span>
                    </div>
                </a>`);

      st.click(() => {
        frappe.silent_print.stationManager.setActiveStation(station.stationName);
        return false;
      });

      return st;
    } else {
      return $(`<a class="recent-item notification-item station-item station-unavailable" onclick="return false;" data-name="${station.stationName}">
                    <div class="notification-body">
                        <div class="message">
                            <div class="station-title">${station.stationName}</div>
                            <div class="notification-timestamp text-muted">
                                ${__('{0} available printer{1}', [0, 's'])}
                            </div>
                        </div>
                    </div>
                    <div class="station-status">
                        <span class="indicator-pill whitespace-nowrap red">
                            ${__('Active but Offline')}
                        </span>
                    </div>
                </a>`);
    }
  }
}
