import {WebSocketPrinter} from "./printer";
import {Setting} from "./interfaces";
import {PrintStation} from "./station_manager";

const frappe = window['frappe'];

export class PrintingBridge {

  printService: WebSocketPrinter;

  constructor(private printer: WebSocketPrinter) {
    this.printService = printer;

    frappe.realtime.on('print-silently', (data) => {
      if (this.printService.isConnected() && this.printService.isActive()) {
        if (!('command' in data) || data.command === 'PDF') {
          frappe.silent_print.printService.submit({
            'type': data.print_type,
            'url': 'file.pdf',
            'file_content': data.pdf
          });
        } else if (data.command === 'ZPL') {
          frappe.silent_print.printService.submit({
            'type': data.print_type,
            'raw_content': data.raw_content
          });
        }
      }
    });
  }
}
