import {WebSocketPrinter} from "./printer";
import {Setting} from "./interfaces";

const frappe = window['frappe'];
declare var $;

interface PrintStationPrinter {
  apiName: string;
  localName: string;
}

export class PrintStation {
  stationName: string;
  printers: PrintStationPrinter[];
  broadcasters: string[];
  active: boolean;

  constructor(stationName: string, printers: PrintStationPrinter[]) {
    this.stationName = stationName;
    this.printers = printers;
    this.broadcasters = [];
    this.active = localStorage.getItem('active-printing-station') === 'true';
  }
  
  public setBroadcasters(users: string[]) {
    this.broadcasters = users;
  }

  public setActive(updateUserSetting: boolean = true) {
    localStorage.setItem('active-printing-station', this.stationName);
    if (updateUserSetting) {
      frappe.model.user_settings.save('Silent Print Station', 'selected', this.stationName);
    }
  }

  public isActive(): boolean {
    return localStorage.getItem('active-printing-station') === this.stationName;
  }
}

export class StationManager {

  private stations: PrintStation[];
  private eventQueue: any[] = [];

  constructor(private printer: WebSocketPrinter) {
    frappe.call('silent_print.utils.station_manager.get_all').then(data => {
      if (data.message) {
        this.stations = data.message.map(s => new PrintStation(s['station_name'], s['printers'].map(p => ({
          apiName: p['api_name'],
          localName: p['local_name']
        } as PrintStationPrinter))));
        
        this.eventQueue.forEach(e => this.handleStationUpdate(e));
        this.eventQueue = [];

        // load from db if no localStorage present
        // @ts-ignore
        if (!localStorage.getItem('active-printing-station') || !this.stations.find(s => s.stationName === localStorage.getItem('active-printing-station'))) {
          frappe.model.user_settings.get('Silent Print Station').then((r) => {
            if (r && 'selected' in r) {
              this.setActiveStation(r.selected, false, false);
            }
          });
        } else {
          this.setActiveStation(localStorage.getItem('active-printing-station'));
        }
      } else {
        // Insufficient Permissions
        frappe.silent_print.tray.hide();
      }
    });

    this.subscribeBroadcasters();
    this.registerShutdownHook();

    this.printer.on('settings', this.onSettings);
    this.printer.on('disconnect', this.onDisconnect);
  }

  onSettings = (settings: Setting) => {
    frappe.call({
      method: 'silent_print.utils.station_manager.advertise',
      args: {
        station_name: settings.stationName,
        available_printers: settings.printers
      },
      callback: (result) => {
        this.enableStation(settings.stationName);
      }
    });
  }

  onDisconnect = () => {
    if (this.printer.getSettings()) {
      this.disableStation(this.printer.getSettings().stationName);
    }
  }
  
  private handleStationUpdate(data): void {
    this.stations.forEach(s => {
      s.setBroadcasters([]);
      if (data[s.stationName]) {
        s.setBroadcasters(data[s.stationName])
      }
    });
    this.printer.emit('stations', this.stations);
  }

  private subscribeBroadcasters(): void {
    frappe.realtime.on("printers", (data) => {
      if (!this.stations) {
        this.eventQueue.push(data);
      } else {
        this.handleStationUpdate(data);
      }
    });

    frappe.realtime.on('refresh-printer', (data) => {
      frappe.socketio.socket.emit("doc_open", 'Silent Print Station', data);
    });

    frappe.realtime.on('select-printer', (data) => {
      this.setActiveStation(data, false, false);
    });

    frappe.realtime.on('new-printer', (data) => {
      this.stations.push(new PrintStation(data['station_name'], data['printers'].map(p => ({
        apiName: p['api_name'],
        localName: p['local_name']
      } as PrintStationPrinter))))
    });
  }

  private registerShutdownHook(): void {
    window.addEventListener("beforeunload", (e: BeforeUnloadEvent) => {
      if (this.printer.isConnected()) {
        fetch('/api/method/silent_print.utils.station_manager.page_close', {
          keepalive: true,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Frappe-CSRF-Token': frappe.csrf_token
          },
          body: JSON.stringify({
            station_name: this.printer.getSettings().stationName
          }),
        });
      }
    });
  }

  public setActiveStation(stationName: string, updateUserSetting: boolean = true, updateServer: boolean = true) {
    this.stations.filter(s => s.stationName === stationName).forEach(s => {
      s.setActive(updateUserSetting);
    });
    if (updateServer) {
      frappe.call({
        method: "silent_print.utils.station_manager.select_print_station",
        args: {
          station: stationName
        }
      });
    }
    this.printer.emit('stations', this.stations);
  }

  enableStation(station: string) {
    frappe.socketio.socket.emit("silent_print_advertise", station);
  }

  disableStation(station: string) {
    frappe.socketio.socket.emit("silent_print_hide", station);
  }
}
