import {TrayPage} from "./page";
import {PrinterPage} from "./printers";
import {SettingsPage} from "./settings";
import {Setting} from "../../core/interfaces";
import {WebSocketPrinter} from "../../core/printer";

declare var $;
declare var __;
const frappe = window['frappe'];

type TrayPageHolder = {
  [name: string]: TrayPage
}

export class PrinterTray {

  private $tray: any;
  private $trayIcon: any;
  private $badge: any;
  private pages: TrayPageHolder;
  private wasConnected: boolean = false;
  private printer: WebSocketPrinter;

  constructor(printService: WebSocketPrinter) {
    this.printer = printService;
    this.setupPages();
    this.inject();

    this.printer.on('connect', this.onConnect);
    this.printer.on('disconnect', this.onDisconnect);
    this.printer.on('stations', (stations) => this.pages.printers['render'](stations))
  }

  onConnect = (ev: Event) => {
    this.wasConnected = true;
    this.toggleIcon('none');
  }

  onDisconnect = (ev: Event) => {
    if (this.wasConnected)
      this.toggleIcon('warning');
  }

  setupPages() {
    this.pages = {};
    this.pages.printers = new PrinterPage();
    this.pages.settings = new SettingsPage();
  }

  inject() {
    const $navbar = $('.navbar .navbar-collapse .navbar-nav');
    this.$tray = $(`<li class="nav-item dropdown dropdown-print">
                          <a
                              class="nav-link printers-icon text-muted"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              href="#"
                              onclick="return false;">
                              <span class="printer-badge">
                                <svg class="icon icon-sm"><use href="#icon-solid-error"></use></svg>
                              </span>
                              <span>
                                  <svg class="icon icon-md"><use href="#icon-printer"></use></svg>
                              </span>
                          </a>
                          <div class="dropdown-menu printers-list dropdown-menu-right" role="menu">
                              <div class="printer-list-header">
                                  <div class="header-items">
                                    <ul class="printer-item-tabs nav nav-tabs" role="tablist">
                                      <li class="printers-category active"
                                        id="printers"
                                      >${__('Printer')}</li>
                                      <li class="printers-category pull-right" id="settings">
                                          ${frappe.utils.icon("setting-gear")}
                                      </li>
                                    </ul>
                                  </div>
                              </div>
                              <div class="printer-list-body">
                                  <div class="panel-printers"></div>
                                  <div class="panel-settings"></div>
                              </div>
                          </div>
                      </li>`);
    this.$trayIcon = this.$tray.find('.printers-icon');
    this.$badge = this.$trayIcon.find('.printer-badge').toggle(false);
    $navbar.prepend(this.$tray);

    this.pages.printers.attach(this.$tray.find('.panel-printers'));
    this.$tray.find('#printers').click(() => this.showPage('printers'));

    this.pages.settings.attach(this.$tray.find('.panel-settings'));
    this.$tray.find('#settings').click(() => this.showPage('settings'));
    // TODO: Implement settings
    this.$tray.find('#settings').hide();

    this.showPage('printers');
  }

  toggleIcon(icon: ('none' | 'warning' | 'error') = 'none') {
    const show = icon !== 'none';
    this.$badge.toggle(show);

    if (show) {
      this.$badge.find('use').attr('href', `#icon-solid-${icon}`);
    }
  }

  showPage(pageName: string) {
    for (const page in this.pages) {
      this.pages[page].hide();
      this.$tray.find('.printer-list-header #' + page).removeClass('active');
    }
    this.$tray.find('.printer-list-header #' + pageName).addClass('active');
    this.pages[pageName].show();
    return false;
  }

  hide(): void {
    this.$tray.toggle(false);
  }
}
