import {
  EventType,
  PrinterOptions,
  Setting,
  TypedResponse,
  WebSocketPrinterEvent,
  WebSocketPrinterEvents
} from "./interfaces";

export class WebSocketPrinter {

  private readonly events: WebSocketPrinterEvents;
  private options: PrinterOptions;
  private websocket: WebSocket;
  private settings: Setting;
  private connected: boolean = false;
  private active: boolean = false;

  constructor(options: any) {
    const defaultOptions: PrinterOptions = {
      url: 'ws://127.0.0.1:12212/printer',
      autoReconnect: true
    }
    // @ts-ignore
    this.options = Object.assign(defaultOptions, options);
    this.events = {stations: [], connect: [], disconnect: [], error: [], settings: [], update: []};
  }

  public connect = (): void => {
    this.websocket = new WebSocket(this.options.url);
    this.websocket.onopen = this.onConnect;
    this.websocket.onclose = this.onDisconnect;
    this.websocket.onmessage = this.onMessage;
    this.websocket.onerror = this.onError;
  }

  private onConnect = (ev: Event): any => {
    this.connected = true;
    this.emit('connect', ev);
  }

  private onMessage = (ev: MessageEvent): any => {
    const data = JSON.parse(ev.data) as TypedResponse;
    this.emit('update', ev);

    if (data.type === 'settings') {
      this.settings = data.data;
      this.emit('settings', this.settings);
    } else if (data.type === 'active') {
      this.active = data.data;
    }
  };

  private onError = (ev: Event): any => {
    this.emit('error', ev);
  };

  private onDisconnect = (ev: Event): any => {
    this.connected = false;
    this.emit('disconnect', ev);

    if (this.options.autoReconnect)
      setTimeout(this.connect, 5000);
  }

  public submit(data: any): void {
    if (Array.isArray(data)) {
      data.forEach(e => {
        this.websocket.send(JSON.stringify(e));
      });
    } else {
      this.websocket.send(JSON.stringify(data));
    }
  };

  public isConnected(): boolean {
    return this.connected;
  }

  public getSettings(): Setting {
    return this.settings;
  }

  public emit(event: EventType, data: any): void {
    this.events[event].forEach(e => e(data));
  }

  public on(event: EventType, callback: WebSocketPrinterEvent) {
    this.events[event].push(callback);
  }

  public off(event: EventType, callback: WebSocketPrinterEvent | undefined) {
    if (callback) {
      this.events[event] = this.events[event].filter(c => c !== callback);
    } else {
      this.events[event] = [];
    }
  }

  public isActive(): boolean {
    return this.active;
  }
}
