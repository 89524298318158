import {TrayPage} from "./page";

declare var __;

export class SettingsPage extends TrayPage {

  setup() {
    this.$container.empty();
    this.$container.html(`
				<div class="frappe-control input-max-width pt-2 mx-3" data-fieldtype="Link" data-fieldname="customer" title="customer">
          <div class="form-group">
            <div class="clearfix">
              <label class="control-label">${__('Selected Printing Station (Database)')}</label>
            </div>
            <div class="control-input-wrapper">
              <div class="control-value like-disabled-input bold">Test</div>
            </div>
          </div>
        </div>
			`);
  }
}
