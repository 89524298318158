declare var $;

export class TrayPage {
  protected $page: any;
  protected $container: any;

  public attach(page: any): void {
    this.$page = page;
    this.$container = $(`<div></div>`).appendTo(this.$page);
    this.$container.hide();
    this.setup();
  }

  public setup(): void {}

  public show(): void {
    this.$container.show();
  }

  public hide(): void {
    this.$container.hide();
  }
}
